import CommonValidation from '../../../utils/common.validation';

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'publishDt':
        return CommonValidation.nonRequiredDate(value);
      case 'title':
        return CommonValidation.requiredValue(value);
      default:
        return '';
    }
  };

  static contentValidate = (type, value, contentType) => {
    switch (contentType) {
      case 1:
        switch (type) {
          case 'linkText':
          case 'link':
            return CommonValidation.requiredValue(value);
          default:
            return '';
        }
      case 3:
        switch (type) {
          case 'content':
            return CommonValidation.requiredValue(value);
          default:
            return '';
        }
      case 4:
        switch (type) {
          case 'relateBlogId':
            return CommonValidation.requiredValue(value);
          default:
            return '';
        }
      default:
        return '';
    }
  };
}

export default Validation;
