import React from 'react';
import {
  Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, Table, TableRow, TableCell, TableDataCell, Page,
} from '../../../components/pdf/Common';
import logo from '../../../asset/logo.png';
import syaban from '../../../asset/syaban.png';
import { groupBy } from '../../../utils/common.helper';

export default function ClaimFile(props) {
  const { header, details } = props.data || {};
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1);
  const dd = String(today.getDate());
  const formattedDate = `${yyyy}年${mm}月${dd}日`;
  const createShopName = (item) => (
    <React.Fragment key={item.id}>
      <Text>
        {item.shopName}
      </Text>
      <Text>
        {item.invoiceTargeted ? '【課税事業者】' : '【免税事業者】'}
      </Text>
    </React.Fragment>
  );
  const detailMap = details ? groupBy(details, 'shopName') : {};
  const detailRowStyle = (index, length) => {
    const base = { flex: '1 0 auto', display: 'flex' };
    return index < length - 1 ? { ...base, borderLeft: 'none', borderTop: 'none' } : { ...base, border: 'none' };
  };

  let cnt = 0;
  let isNextPage = false;

  const detItem = (shopName, detArray, idx) => (
    <React.Fragment key={shopName + idx}>
      <TableRow style={{ display: 'flex', alignItems: 'stretch' }} key={shopName} borderTop={idx > 0}>
        <TableDataCell
          flexBasis="20%"
          style={{
            textAlign: 'center', justifyContent: 'center', flexWrap: 'wrap', display: 'flex', flexDirection: 'column',
          }}
        >
          {createShopName(detailMap[shopName][0])}
        </TableDataCell>
        <Col flexBasis="80%" style={{ display: 'flex', flexDirection: 'column' }}>
          {detArray.map((item, index) => (
            <TableRow
              key={item.id}
              style={detailRowStyle(index, detArray.length)}
            >
              <TableDataCell flexBasis="41.25%" left style={{ paddingLeft: '5px', flexWrap: 'wrap' }}>
                <Text>{item.productName}</Text>
              </TableDataCell>
              <TableDataCell flexBasis="18.75%" right>
                <Text>{item.unitPrice}</Text>
              </TableDataCell>
              <TableDataCell flexBasis="10%" right>
                <Text>{item.quantity}</Text>
              </TableDataCell>
              <TableDataCell flexBasis="20%" right>
                <Text>{item.totalPrice}</Text>
              </TableDataCell>
              <TableDataCell flexBasis="10%">
                <Text>{`${item.taxRate}%`}</Text>
              </TableDataCell>
            </TableRow>
          ))}
        </Col>
      </TableRow>
      <View break={isNextPage} />
    </React.Fragment>
  );
  const detailLine = (shopName) => {
    let shopAllItems = detailMap[shopName];
    const tempCnt = cnt + detailMap[shopName].length;
    const nextPageColumnSize = isNextPage ? 30 : 15;
    if (tempCnt <= nextPageColumnSize) {
      cnt = tempCnt;
      return detItem(shopName, shopAllItems);
    }
    isNextPage = true;
    const result = [];
    const firstChunkSize = 15 - cnt;
    let currentItems = shopAllItems.slice(0, firstChunkSize);
    if (firstChunkSize > 0)result.push(currentItems);
    shopAllItems = shopAllItems.slice(firstChunkSize);
    while (shopAllItems.length > 0) {
      currentItems = shopAllItems.slice(0, 30);
      result.push(currentItems);
      shopAllItems = shopAllItems.slice(30);
    }
    cnt = result[result.length - 1].length;
    return (
      result.map((items, index) => (
        detItem(shopName, items, index)
      )));
  };
  return (
    <Document>
      <Page
        size="A4"
        wrap
      >
        <Root>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Image
              src={logo}
              style={{
                width: '25%',
              }}
            />
          </View>

          <View style={{ fontSize: '15px', flexDirection: 'row' }}>
            <Text style={{
              textAlign: 'center', borderBottom: '1px solid #000', fontFamily: 'NotoSansJP-Bold', whiteSpace: 'pre',
            }}
            >
              {header.companyName ? `${header.companyName}   御中` : `${header.customerName}   様`}
            </Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>
              {`発行日：${formattedDate}`}
            </Text>
          </View>
          <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Text style={{ textAlign: 'center' }}>{`管理番号：${header.claimNumber}`}</Text>
          </View>
          <Box style={{
            borderBottom: '3px double #000', marginTop: '15px', marginBottom: '15px', textAlign: 'center',
          }}
          >
            <Title>請求書</Title>
          </Box>
          <Grid>
            <Col flexBasis="60%">
              <Grid>
                <Col
                  flexBasis="20%"
                  style={{
                    alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                  }}
                >
                  <View><Text>開催日時</Text></View>
                </Col>
                <Col
                  borderBottom
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', flexDirection: 'row',
                  }}
                >
                  {
                    header.heldDateTime && (
                      <Col
                        flexBasis="100%"
                        style={{
                          alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                        }}
                      >
                        <View><Text>{header.heldDateTime}</Text></View>
                      </Col>
                    )
                  }
                  {
                    !header.heldDateTime && (
                      <>
                        <Col
                          flexBasis="30%"
                          style={{
                            alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                          }}
                        >
                          <View><Text>{header.heldDate}</Text></View>
                        </Col>
                        <Col
                          flexBasis="70%"
                          style={{
                            alignItems: 'left', textAlign: 'left', justifyContent: 'left',
                          }}
                        >
                          <View><Text>{header.heldCharTime}</Text></View>
                        </Col>
                      </>
                    )
                  }
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col flexBasis="20%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
                  <View><Text>合計金額</Text></View>
                </Col>
                <Col
                  flexBasis="80%"
                  borderBottom
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                >
                  <Text>{`¥${header.customerBillPrice ? header.customerBillPrice : ''}`}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col flexBasis="20%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
                  <View><Text>支払方法</Text></View>
                </Col>
                <Col
                  flexBasis="80%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.paymentMethodName}</Text>
                </Col>
              </Grid>
              <Grid style={{ marginTop: '2px' }}>
                <Col flexBasis="20%" style={{ alignItems: 'left', textAlign: 'left', justifyContent: 'left' }}>
                  <View><Text>支払期限</Text></View>
                </Col>
                <Col
                  flexBasis="80%"
                  style={{
                    minWidth: '164px', wordWrap: 'break-word', maxWidth: '260px', paddingLeft: '3px', textAlign: 'left',
                  }}
                  borderBottom
                >
                  <Text>{header.paymentDueDate}</Text>
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="40%">
              <Box style={{ position: 'relative', textAlign: 'left' }}>
                <Text>ケータリングセレクション</Text>
                <Text>株式会社Cqree</Text>
                <Text>〒140-0013</Text>
                <Text>東京都品川区南大井6-16-16</Text>
                <Text>鈴中ビル大森4F</Text>
                <Text>TEL：0120-262-005</Text>
                <Text>登録番号：T3-0104-0111-4543</Text>
              </Box>
              <Image
                src={syaban}
                style={{
                  width: '30%', position: 'absolute', bottom: '5px', right: '-5px',
                }}
              />
            </Col>
          </Grid>
          <View style={{ marginTop: '5px' }}><Text>下記の通り御請求申し上げます。</Text></View>
          <Table style={{ marginTop: '5px' }}>
            <TableRow borderTop subTitle key="header">
              <TableCell flexBasis="20%"><Text>店舗名</Text></TableCell>
              <TableCell flexBasis="33%"><Text>商品名</Text></TableCell>
              <TableCell flexBasis="15%"><Text>単価(税込)</Text></TableCell>
              <TableCell flexBasis="8%"><Text>個数</Text></TableCell>
              <TableCell flexBasis="16%"><Text>合計金額(税込)</Text></TableCell>
              <TableCell flexBasis="8%"><Text>税区分</Text></TableCell>
            </TableRow>
            {Object.keys(detailMap).map((shopName) => (
              (
                detailLine(shopName)
              )

            ))}
          </Table>

          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '53%',
              padding: 4,
              borderRight: '0.5px solid #000',
              textAlign: 'left',
            }}
            />
            <View style={{
              flexBasis: '23%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>小計</Text>
            </View>
            <View style={{
              flexBasis: '16%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.totalPrice ? header.totalPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '8%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '53%',
              padding: 4,
              borderRight: '0.5px solid #000',
            }}
            />
            <View style={{
              flexBasis: '23%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>ポイント利用額</Text>
            </View>
            <View style={{
              flexBasis: '16%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.pointUsagePrice ? header.pointUsagePrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '8%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '53%',
              paddingTop: 10,
              borderRight: '0.5px solid #000',
              textAlign: 'left',
            }}
            >
              <Text style={{ textAlignVertical: 'bottom' }}>（適格請求書発行事業者ご利用分）</Text>
            </View>
            <View style={{
              flexBasis: '23%',
              padding: 4,
              textAlign: 'left',
              borderLeft: '0.5px solid #000',
              borderRight: '0.5px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>割引額後総合計金額(税込)</Text>
            </View>
            <View style={{
              flexBasis: '16%',
              backgroundColor: '#E7E6E6',
              padding: 4,
              textAlign: 'right',
              borderLeft: '0.5px solid #000',
              borderRight: '1px solid #000',
              borderBottom: '1px solid #000',
            }}
            >
              <Text>{`¥${header.customerBillPrice ? header.customerBillPrice : 0}`}</Text>
            </View>
            <View style={{ flexBasis: '8%' }} />
          </Box>

          <Box style={{ flexDirection: 'row', marginTop: 5 }}>
            <View style={{ flexBasis: '76%' }}>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑧：消費税8%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationEightPercentTotalPrice ? header.taxationEightPercentTotalPrice : 0}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationEightPercentTotalTaxPrice ? header.taxationEightPercentTotalTaxPrice : 0}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑩：消費税10%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationTenPercentTotalPrice ? header.taxationTenPercentTotalPrice : 0 }</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.taxationTenPercentTotalTaxPrice ? header.taxationTenPercentTotalTaxPrice : 0}</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
            </View>
            <View style={{ flexBasis: '24%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{ flexBasis: '76%', marginTop: 5 }}>
              <Text>（非適格請求書発行事業者ご利用分）</Text>
            </View>
            <View style={{
              flexBasis: '24%',
            }}
            />
          </Box>
          <Box style={{ flexDirection: 'row', marginTop: 5 }}>
            <View style={{ flexBasis: '76%' }}>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑧：消費税8%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionEightPercentTotalPrice ? header.exemptionEightPercentTotalPrice : 0 }</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionEightPercentTotalTaxPrice ? header.exemptionEightPercentTotalTaxPrice : 0 }</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
              <Col borderBottom style={{ flexDirection: 'row' }}>
                <Col left style={{ flexBasis: '17%' }}><View><Text>⑩：消費税10%</Text></View></Col>
                <Col left style={{ flexBasis: '18%' }}><View><Text>対象合計金額</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionTenPercentTotalPrice ? header.exemptionTenPercentTotalPrice : 0 }</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
                <Col style={{ flexBasis: '15%' }}><View><Text>内消費税</Text></View></Col>
                <Col style={{ flexBasis: '20%' }} right><View><Text>{header.exemptionTenPercentTotalTaxPrice ? header.exemptionTenPercentTotalTaxPrice : 0 }</Text></View></Col>
                <Col style={{ flexBasis: '5%' }}><View><Text>円</Text></View></Col>
              </Col>
            </View>
            <View style={{ flexBasis: '24%' }} />
          </Box>
          <Box style={{ flexDirection: 'row' }}>
            <View style={{
              flexBasis: '53%',
              padding: 4,
              textAlign: 'left',
            }}
            >
              <Text>※税区分「8%」は軽減税率対象</Text>
            </View>
            <View style={{
              flexBasis: '47%',
            }}
            />
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <View><Text>■備考：</Text></View>
            <Col borderTop borderRight borderBottom borderLeft left style={{ paddingLeft: '4px', justifyContent: 'start', minHeight: '40px' }}>
              {header.remarks && header.remarks.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Box>
          <Box
            style={{
              marginTop: '20px',
            }}
          >
            <Col
              borderTop
              borderRight
              borderBottom
              borderLeft
              style={{
                paddingLeft: '4px',
                justifyContent: 'center',
                height: '40px',
                backgroundColor: '#E7E6E6',
                textAlign: 'center',
                borderLeft: '1px solid #000',
                borderRight: '1px solid #000',
                borderBottom: '1px solid #000',
              }}
            >
              お振り込み先：
              {header.accountInfo}
              <Text>
                ※お振り込み手数料は、お客様のご負担でお願い致しております。
              </Text>
            </Col>
          </Box>
        </Root>
      </Page>
    </Document>
  );
}
