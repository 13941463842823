import React, { useState, useEffect } from 'react';
import {
  DialogActions, Box, Button, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from './DataTable';
import { opportunityListActions } from '../../../redux/opportunity/opportunity/opportunityListState';
import { FormTitle, DialogContent, DatePicker } from '../../../components/atoms/Base';
import Dialog from '../../../components/atoms/Dialog';

const headCells = [
  { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
  { id: 'salesUserName', label: '担当者', sortId: 'sales_user_name' },
  { id: 'opportunityStatusName', label: 'ステータス', sortId: 'opportunity_status_name' },
  { id: 'heldDate', label: '開催日', sortId: 'held_date' },
  { id: 'companyName', label: '会社名', sortId: 'company_name' },
  { id: 'customerName', label: 'お客様名', sortId: 'customer_name' },
  { id: 'customerEmail', label: 'メールアドレス', sortId: 'customer_email' },
  { id: 'customerPhone', label: '電話番号', sortId: 'customer_phone' },
  { id: 'shopRemarks', label: '店舗向け備考', sortId: 'shop_remarks' },
  { id: 'deliveryAddress', label: '納品先住所', sortId: 'delivery_address' },
];

export default function SelectOpportunityDialog(props) {
  const { isOpen, onClose, onSelect } = props;
  const [datas, setDatas] = useState({});
  const [selected, setSelected] = React.useState([]);
  const [condition, setCondition] = useState({
    page: 0,
    records: 50,
    order: 'asc',
    orderBy: '',
    customerName: '',
    heldDate: '',
  });
  const dispatch = useDispatch();
  const dbDatas = useSelector((state) => state.opportunityListStore.datas);

  useEffect(() => {
    if (isOpen) {
      const tmpCondition = {
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
        customerName: '',
        heldDate: '',
      };
      setCondition(tmpCondition);
      dispatch(opportunityListActions.getDatas(tmpCondition));
      setSelected([]);
    }
  }, [isOpen]);
  useEffect(() => {
    if (dbDatas) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const onChangePage = (_, newPage) => {
    const tmpCondition = {
      ...condition,
      page: newPage,
    };
    setCondition(tmpCondition);
    dispatch(opportunityListActions.getDatas(tmpCondition));
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tmpCondition = {
      ...condition,
      page: 0,
      records: rowsPerPage,
    };
    setCondition(tmpCondition);
    dispatch(opportunityListActions.getDatas(tmpCondition));
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tmpCondition = {
      ...condition,
      page: 0,
      orderBy,
      order,
    };
    setCondition(tmpCondition);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    const tmpCondition = {
      ...condition,
      [name]: value,
      page: 0,
    };
    setCondition(tmpCondition);
  };

  const onSearch = () => {
    dispatch(opportunityListActions.getDatas(condition));
  };

  return (
    <Dialog
      fullWidth
      title="案件選択"
      maxWidth="false"
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Box>
          <Box mb={2} display={{ xs: 'block', md: 'flex' }}>
            <Box>
              <FormTitle title="案件番号" />
              <TextField autoComplete="off" name="opportunityNumber" value={condition.opportunityNumber} onChange={onChange} />
            </Box>
            <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }}>
              <FormTitle title="開催日" />
              <DatePicker value={condition.heldDate} fullWidth name="heldDate" onChange={onChange} />
            </Box>
            <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }}>
              <FormTitle title="会社名" />
              <TextField autoComplete="off" value={condition.companyName} fullWidth name="companyName" onChange={onChange} />
            </Box>
            <Box mx={{ xs: 0, md: 1 }} mt={{ xs: 1, md: 0 }} display="flex" alignItems="flex-end"><Button variant="contained" size="small" onClick={onSearch}>検索</Button></Box>
          </Box>
          <DataTable
            rows={datas.content || []}
            headCells={headCells}
            page={condition.page}
            rowsPerPage={condition.records}
            totalElements={datas.totalElements}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            handleRequestSort={handleRequestSort}
            order={condition.order}
            orderBy={condition.orderBy}
            setSelected={(v) => setSelected(v)}
            selected={selected}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>キャンセル</Button>
        <Button disabled={selected.length <= 0} onClick={() => onSelect(selected)} color="primary">設定</Button>
      </DialogActions>

    </Dialog>
  );
}
