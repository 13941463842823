import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ConfirmDialog from '../../../components/templates/ConfirmDialog';
import { claimActions } from '../../../redux/opportunity/claim/claimState';
import Dialog from '../../../components/atoms/Dialog';
import { DialogContent } from '../../../components/atoms/Base';

export default function PaymentLinkCreateDialog(props) {
  const {
    isOpen, onClose, form, totalPrice,
  } = props;
  const [isOpenReCreate, setOpenReCreate] = useState(false);
  const [isNew, setNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState({});
  const [isError, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setErrMsg({
        totalPrice: '',
        customerEmail: '',
        strinpeRemarks: '',
      });
      setError(false);
      if (totalPrice < 50 || !!form.paymentLink || !form.stripeRemarks || !form.customerEmail) {
        setOpenReCreate(true);
        setNew(false);
        setErrMsg({
          totalPrice: totalPrice < 50 ? '合計税込額が50円未満の場合、作成できません' : '',
          customerEmail: !form.customerEmail ? '支払メールを入力してください' : '',
          strinpeRemarks: !form.stripeRemarks ? 'Stripe連携用備考を入力してください' : '',
        });
        setError(totalPrice < 50 || !form.stripeRemarks || !form.customerEmail);
      } else {
        setLoading(true);
        setNew(true);
        dispatch(claimActions.createPaymentLink(
          { id: form.id, customerId: form.customerId || null },
        ));
      }
    }
  }, [isOpen]);

  const onCreate = () => {
    setLoading(true);
    dispatch(claimActions.createPaymentLink(
      { id: form.id, customerId: form.customerId || null },
    ));
  };

  return (
    <>
      {
        isOpen && !form.paymentLink && loading && (
          <CircularProgress color="inherit" size={20} />
        )
      }
      {
        !isNew && isOpen && isOpenReCreate && !isError && (
          <ConfirmDialog
            open={isOpenReCreate}
            onClose={onClose}
            title="支払リンクの作成"
            content="支払リンクの作成を再作成します。"
            actionText="作成する"
            onClick={onCreate}
          />
        )
      }
      {
        isOpen && isOpenReCreate && isError && (
          <Dialog
            title="エラー"
            isOpen={isOpenReCreate}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            isError
          >
            <DialogContent>
              <>
                {errMsg && !!errMsg.totalPrice && (
                  <Box mb={1} display={{ xs: 'block', md: 'flex' }}>
                    <InfoOutlinedIcon color="secondary" style={{ marginRight: '4px' }} />
                    {errMsg.totalPrice}
                  </Box>
                )}
                {errMsg && !!errMsg.customerEmail && (
                  <Box mb={1} display={{ xs: 'block', md: 'flex' }}>
                    <InfoOutlinedIcon color="secondary" style={{ marginRight: '4px' }} />
                    {errMsg.customerEmail}
                  </Box>
                )}
                {errMsg && !!errMsg.strinpeRemarks && (
                  <Box mb={1} display={{ xs: 'block', md: 'flex' }}>
                    <InfoOutlinedIcon color="secondary" style={{ marginRight: '4px' }} />
                    {errMsg.strinpeRemarks}
                  </Box>
                )}
              </>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={onClose}>閉じる</Button>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
}
