import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { blogActions } from './blogState';
import {
  getDatas, deleteDatas, getDataById, uploadFiles,
} from '../../../services/base.service';

function* getBlogs({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.BLOG.BLOG, payload);
    yield put(blogActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getCsvDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.BLOG.BLOG_CSV, payload);
    yield put(blogActions.getCsvDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.PAGE_SETTING.BLOG.BLOG_ID, payload);
    yield put(blogActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFile') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));
    formData.append('imageFile', payload.imageFile || null);

    const result = yield call(uploadFiles, REST_API.PAGE_SETTING.BLOG.BLOG, formData);
    yield put(blogActions.saveDataSuccess(result));
  } catch (_) { }
}

function* deleteBlogs({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.PAGE_SETTING.BLOG.BLOG, payload);
    yield put(blogActions.deleteDatasSuccess(result));
  } catch (_) { }
}

function* getkeywords() {
  try {
    const result = yield call(getDatas, REST_API.PAGE_SETTING.BLOG.BLOG_KEYWORD);
    yield put(blogActions.getkeywordsSuccess(result));
  } catch (_) { }
}

function* blogSaga() {
  yield takeLatest('blog/getDatas', getBlogs);
  yield takeLatest('blog/getCsvDatas', getCsvDatas);
  yield takeLatest('blog/getData', getData);
  yield takeLatest('blog/saveData', save);
  yield takeLatest('blog/deleteDatas', deleteBlogs);
  yield takeLatest('blog/getkeywords', getkeywords);
}

export default blogSaga;
