import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField,
} from '@mui/material';
import { Launch as LaunchIcon } from '@mui/icons-material';
import {
  SuccessSnackbar, FormSwitch, RecordBox, DateTimePicker, FormErrorText,
  FreeMultiBox,
} from '../../../components/atoms/Base';
import { blogActions } from '../../../redux/pageSetting/blog/blogState';
import DataGrid from '../../../components/templates/DataGrid';
import NumberInput from '../../../components/atoms/NumberInput';
import SingleFileInput from '../../../components/templates/SingleFileInput';
import Validation from './validation';
import SystemInfo from '../../../components/templates/SystemInfo';
import detailUrl from '../../../constants/frontUrls';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import './blog.css';

const DEFAULT_FORM = {
  id: '',
  category: '',
  subCategory: '',
  keywords: [],
  title: '',
  imagePath: '',
  publishDt: '',
  published: false,
  displayOrder: '',
  viewNumber: '',
  updatedAt: '',
  updatedBy: '',
  createdAt: '',
  createdBy: '',
};

export default function BlogDetailInfoCard(props) {
  const { id, isDisabled } = props;
  const dispatch = useDispatch();
  const dbData = useSelector((state) => state.blogStore.data);
  const isSaveSuccess = useSelector((state) => state.blogStore.isSaveSuccess);
  const dbCategory = useSelector((state) => state.blogStore.keywords);
  const saveId = useSelector((state) => state.blogStore.saveId);
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isEdit, setEdit] = React.useState(false);
  const [inputForm, setInputForm] = React.useState(DEFAULT_FORM);
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [keywords, setkeywords] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState({ publishDt: '' });

  React.useEffect(() => {
    if (!id) {
      setEdit(true);
      setInputForm(DEFAULT_FORM);
      setForm(DEFAULT_FORM);
    }
  }, []);

  React.useEffect(() => {
    if (id && dbData) {
      setInputForm(dbData);
      setForm(dbData);
      setErrorMessages(DEFAULT_FORM);
    }
  }, [dbData]);
  React.useEffect(() => {
    if (isSaveSuccess && !id) {
      window.location.href = detailUrl.BLOG_DETAIL + saveId;
    }
    if (isSaveSuccess && id) {
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(blogActions.resetFlg());
      dispatch(blogActions.getData({ id }));
    }
  }, [isSaveSuccess]);

  React.useEffect(() => {
    if (dbCategory) {
      setkeywords(dbCategory);
    }
  }, [dbCategory]);

  const onEdit = () => {
    dispatch(blogActions.getData({ id }));
    setEdit(true);
  };

  const onClose = () => {
    setEdit(false);
  };

  const onSuccessSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setInputForm({
      ...inputForm,
      [name]: value,
    });
    setErrorMessages({ ...errorMessages, [name]: Validation.formValidate(name, value) });
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;
    Object.keys(inputForm).forEach((key) => {
      const msg = Validation.formValidate(key, inputForm[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (inputForm.published && !inputForm.metaDescription) {
      tempMessage = {
        ...tempMessage,
        metaDescription: '公開時、記事概要の設定は必須です',
      };
      isError = true;
    }
    setErrorMessages(tempMessage);
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) return;
    const saveinputForm = {
      ...inputForm,
      id,
    };
    dispatch(blogActions.saveData(saveinputForm));
    setInputForm(DEFAULT_FORM);
    onClose();
  };

  return (
    <>
      {!isEdit && !isDisabled && (
        <Box textAlign="right">
          <Button sx={{ width: { xs: '100%', md: '' } }} variant="contained" size="small" onClick={onEdit}>編集</Button>
        </Box>
      )}
      <RecordBox>
        <DataGrid title="カテゴリー" isEdit={isEdit} value={form.category}>
          <TextField value={inputForm.category} fullWidth name="category" onChange={onChange} inputProps={{ maxLength: 100 }} />
        </DataGrid>
        <DataGrid
          title="キーワード"
          isEdit={isEdit}
          value={(
            <FreeMultiBox
              name="keywords"
              options={keywords}
              value={inputForm.keywords}
              onChange={onChange}
              isDisabled
            />
)}
        >
          <FreeMultiBox
            name="keywords"
            options={keywords}
            value={inputForm.keywords}
            onChange={onChange}
          />
        </DataGrid>
        <DataGrid title="タイトル" isEdit={isEdit} value={form.title} isRequired>
          <TextField value={inputForm.title} fullWidth name="title" onChange={onChange} inputProps={{ maxLength: 100 }} error={!!errorMessages.title} required />
          <FormErrorText>{errorMessages.title}</FormErrorText>
        </DataGrid>
        <SingleFileInput
          title="イメージ"
          isEdit={isEdit}
          imagePath="imagePath"
          imageFile="imageFile"
          isImageOnly
          inputForm={inputForm}
          form={form}
          setInputForm={setInputForm}
        />
        <DataGrid title="執筆者" value={form.authorUserName} isEdit={isEdit}>
          <DataSelectList
            name="authorUserId"
            target="users"
            value={inputForm.authorUserId}
            onChange={onChange}
            fullWidth
          />
        </DataGrid>
        <DataGrid title="公開日時" value={form.publishDt} isEdit={isEdit}>
          <DateTimePicker error={!!errorMessages.publishDt} value={inputForm.publishDt} name="publishDt" onChange={onChange} />
          <FormErrorText>{errorMessages.publishDt}</FormErrorText>
        </DataGrid>
        <DataGrid title="公開" isEdit={isEdit} value={form.published ? '公開' : '非公開'}>
          <FormSwitch checked={inputForm.published} name="published" onChange={onChange} label="公開する" />
        </DataGrid>
        <DataGrid title="表示順" isEdit={isEdit} value={form.displayOrder}>
          <NumberInput value={inputForm.displayOrder} fullWidth name="displayOrder" onChange={onChange} />
        </DataGrid>
        <DataGrid title="概要" isEdit={isEdit} value={form.metaDescription}>
          <TextField value={inputForm.metaDescription} fullWidth name="metaDescription" onChange={onChange} inputProps={{ maxLength: 100 }} />
          <FormErrorText>{errorMessages.metaDescription}</FormErrorText>
        </DataGrid>
        <DataGrid title="閲覧数" value={form.viewNumber} />

        {!isEdit && (
        <DataGrid
          title="幹事のコツページURL"
          value={(
            <Box display="flex">
              <a target="_blank" rel="noopener noreferrer" href={`https://catering-selection.jp/blog/detail/${form.id}`}>{`https://catering-selection.jp/blog/detail/${form.id}`}</a>
              <LaunchIcon />
            </Box>
                          )}
        />
        )}

        {!isEdit && form.id && (
          <Box mt={2}><SystemInfo data={form} /></Box>
        )}

        {isEdit && (
          <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <Button color="inherit" variant="contained" size="small" onClick={onClose} style={{ marginRight: '10px' }}>キャンセル</Button>
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
      </RecordBox>
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSuccessSnackbarClose}
        message={successMessage}
      />
    </>
  );
}
