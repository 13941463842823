import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, FormHelperText,
  Chip,
  TextField,
} from '@mui/material';
import { Launch } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { claimActions } from '../../../redux/opportunity/claim/claimState';
import { claimDetailActions } from '../../../redux/opportunity/claim/claimDetailState';
import ClaimDetailDialog from './ClaimDetailDialog';
import {
  RecordBox, withParams, FormErrorText, SuccessSnackbar, TabPanel,
  DatePicker, Link, TextArea,
  ClipboardCopy,
} from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import DetailTable from '../common/detailTable/DetailTable';
import detailUrl from '../../../constants/frontUrls';
import Tabs from '../../../components/templates/Tabs';
import ClaimFileDialog from './file/ClaimFileDialog';
import MailerDialog from './MailerDialog';
import DataSelectList from '../../../components/atoms/dataList/DataSelectList';
import Validation from './validation';
import { isScreenEditDisabled, isScreenDeleteDisabled } from '../../../utils/authCheck.helper';
import NumberInput from '../../../components/atoms/NumberInput';
import ReceiptDownloadButton from './file/ReceiptDownloadButton';
import SystemInfo from '../../../components/templates/SystemInfo';
import Accordion from '../../../components/templates/Accordion';
import ClaimFileDownloadButton from './file/ClaimFileDownloadButton';
import RecordHeader, { RecordHeaderButton } from '../../../components/templates/RecordHeader';
import SelectOpportunityDialog from './SelectOpportunityDialog';
import PaymentLinkCreateDialog from './PaymentLinkCreateDialog';

const defaultErrorMessage = {
  targets: '',
  claimStatusCode: '',
  paymentDate: '',
};

class ClaimDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.claimDetailHeadCells = [
      { id: 'name', label: '店舗/業者', sortId: 'name' },
      { id: 'productName', label: '商品名', sortId: 'product_name' },
      {
        id: 'unitPrice', label: '税込単価', sortId: 'unit_price', numeric: true,
      },
      {
        id: 'quantity', label: '個数', sortId: 'quantity', numeric: true,
      },
      { id: 'taxRate', label: '税率', sortId: 'tax_rate' },
      {
        id: 'burdenPrice', label: 'Cqree負担税込額', sortId: 'burden_price', numeric: true,
      },
      {
        id: 'totalPrice', label: '合計税込額', sortId: 'total_price', numeric: true,
      },
      { id: 'claimed', label: '手数料請求対象', sortId: 'claimed' },
      { id: 'reportHidden', label: '帳票非表示', sortId: 'report_hidden' },
    ];

    const defaultForm = {
      data: {
        id,
        claimNumber: '',
        opportunityNumber: '',
        opportunityId: null,
        totalPrice: 0,
        commissionPrice: 0,
        paymentDate: '',
        claimStatusCode: 1,
        status: '',
        paymentLink: '',
        paymentDueDate: '',
        pointUsagePrice: 0,
      },
      targets: [],
    };

    const searchCondition = {
      order: 'asc',
      orderBy: 'display_order',
      claimId: id,
    };
    let successMessage = '';
    let isSuccessOpen = false;
    if (props.claimInfo.isCreateSuccess) {
      props.dispatch(claimActions.resetFlgs());
      successMessage = '保存しました。';
      isSuccessOpen = true;
    }

    this.state = {
      isOpen: false,
      isSuccessOpen,
      successMessage,
      isMailerOpen: false,
      form: defaultForm,
      inputForm: defaultForm,
      datas: [],
      isEdit: !id,
      tabValue: 0,
      selectedDetailId: null,
      mailerTitle: '',
      errorMessages: defaultErrorMessage,
      searchCondition,
      isPushCreatePaymentLink: false,
      isPushPaymentLink: false,
      isNotEdit: isScreenEditDisabled(props.loginUser.userInfo),
      isNotDelete: isScreenDeleteDisabled(props.loginUser.userInfo),
      isSelectOpen: false,
    };
    if (id) {
      props.dispatch(claimActions.getData({ id }));
      props.dispatch(claimDetailActions.getDatas(searchCondition));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claimInfo.data !== this.props.claimInfo.data) {
      if (this.props.claimInfo.data) {
        this.setData(this.props.claimInfo.data);
      }
    }
    if (prevProps.claimInfo.paymentLink !== this.props.claimInfo.paymentLink) {
      if (this.props.claimInfo.paymentLink) {
        this.setPaymentLink(this.props.claimInfo.paymentLink);
      }
    }
    if (prevProps.claimInfo.isSaveSuccess
      !== this.props.claimInfo.isSaveSuccess) {
      if (this.props.claimInfo.isSaveSuccess) {
        this.saveSuccess(this.props.claimInfo.saveId);
      }
    }
    if (prevProps.claimDetailInfo.datas !== this.props.claimDetailInfo.datas) {
      if (this.props.claimDetailInfo.datas) {
        this.setDatas(this.props.claimDetailInfo.datas);
      }
    }
    if (prevProps.claimDetailInfo.isSaveSuccess !== this.props.claimDetailInfo.isSaveSuccess) {
      if (this.props.claimDetailInfo.isSaveSuccess) {
        this.detailSaveSuccess(false);
      }
    }
    if (prevProps.claimDetailInfo.isDeleteSuccess !== this.props.claimDetailInfo.isDeleteSuccess) {
      if (this.props.claimDetailInfo.isDeleteSuccess) {
        this.detailSaveSuccess(true);
      }
    }
    if (prevProps.claimInfo.isSendSuccess !== this.props.claimInfo.isSendSuccess) {
      if (this.props.claimInfo.isSendSuccess) {
        this.sendSuccess();
      }
    }
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { inputForm, errorMessages } = this.state;

    this.setState({
      inputForm: {
        ...inputForm,
        data: {
          ...inputForm.data,
          [name]: value,
        },
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  onDelete = (deleteId) => {
    this.props.dispatch(claimDetailActions.deleteData({ ids: [deleteId] }));
  };

  onClickEditClaim = () => {
    const { form } = this.state;
    this.props.dispatch(claimActions.getData({ id: form.data.id }));
    this.setState({ isEdit: true, errorMessages: defaultErrorMessage });
  };

  setPaymentLink = (paymentLink) => {
    const { form } = this.state;
    const tempForm = {
      ...form,
      data: {
        ...form.data,
        paymentLink,
      },
    };
    this.props.dispatch(claimActions.getData({ id: form.data.id }));
    this.setState({
      form: tempForm,
      isPushCreatePaymentLink: false,
      isSuccessOpen: true,
      successMessage: '作成しました。',
    });
  };

  onClickSave = () => {
    const { inputForm, errorMessages, form } = this.state;
    let tempMessage = errorMessages;
    let isError = false;
    let tempForm = inputForm;
    if (form.data.affiliationId) {
      tempForm = {
        ...tempForm,
        data: {
          ...tempForm.data, pointUsagePrice: 0,
        },
      };
    }

    Object.keys(tempForm.data).forEach((key) => {
      if (!form.data.id) return;
      const msg = Validation.formValidate(key, tempForm.data[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    const targetsMsg = !tempForm.targets || tempForm.targets.length === 0 ? '選択してください' : '';
    if (targetsMsg) {
      isError = true;
    }
    tempMessage = {
      ...tempMessage,
      targets: targetsMsg,
    };

    this.setState({ errorMessages: tempMessage });
    if (isError) return;
    this.props.dispatch(claimActions.saveData(tempForm));
  };

  saveSuccess = (saveId) => {
    const { form } = this.state;
    if (!form.data.id) {
      this.props.dispatch(claimActions.createSuccess());
      window.location.href = detailUrl.CLAIM_DETAIL + saveId;
      return;
    }
    this.props.dispatch(claimActions.getData({ id: saveId }));
    this.setState({
      isSuccessOpen: true, successMessage: '保存しました。', isOpen: false, isEdit: false,
    });
  };

  detailSaveSuccess = (isDelete) => {
    const { searchCondition, form } = this.state;
    this.props.dispatch(claimDetailActions.getDatas(searchCondition));
    this.props.dispatch(claimActions.getData({ id: form.data.id }));
    this.setState({
      isSuccessOpen: true, successMessage: isDelete ? '削除しました。' : '保存しました。',
    });
  };

  sendSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(claimActions.getData({ id: form.data.id }));
    this.setState({
      isSuccessOpen: true, successMessage: '送信しました。',
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(claimDetailActions.getDatas(searchCondition));
  };

  onClickCancel = () => {
    const { form } = this.state;
    this.setState({ inputForm: form, isEdit: false });
  };

  onAdd = () => {
    this.setState({ selectedDetailId: null, isOpen: true });
  };

  onEdit = (_, id, row) => {
    this.setState({ selectedDetailId: row.id, isOpen: true });
  };

  onSendClaim = () => {
    this.setState({ isMailerOpen: true });
  };

  setData = (form) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      id: form.data.id,
    };
    this.setState({ form, inputForm: form, searchCondition: tempCondition });
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClose = () => {
    this.setState({
      isOpen: false,
      isMailerOpen: false,
    });
  };

  onCloseAtSendFileSuccess = () => {
    this.setState({
      isOpen: false,
      isMailerOpen: false,
      isSuccessOpen: true,
      successMessage: '送信しました。',
    });
  };

  onSelect = (selected) => {
    const { inputForm, errorMessages } = this.state;
    const tempMessage = errorMessages;

    const newTargets = selected.reduce((acc, item) => {
      const exists = inputForm.targets.some((target) => target.id === item.id);
      if (!exists) {
        acc.push({
          id: item.id,
          opportunityNumber: item.opportunityNumber,
        });
      }
      return acc;
    }, []);

    let updatedInputForm = inputForm;
    if (newTargets.length > 0) {
      updatedInputForm = {
        ...inputForm,
        targets: [...inputForm.targets, ...newTargets],
      };
    }
    this.setState({
      isSelectOpen: false,
      inputForm: updatedInputForm,
      errorMessages: {
        ...tempMessage,
        targets: '',
      },
    });
  };

  onDeleteTargets = (_, data) => {
    const { inputForm, errorMessages } = this.state;
    let tempMessage = errorMessages;
    const newTargets = inputForm.targets.filter((target) => target.id !== data.id);
    if (!newTargets || newTargets.length === 0) {
      tempMessage = {
        ...tempMessage,
        targets: '選択してください',
      };
    }
    this.setState({
      inputForm: {
        ...inputForm,
        targets: newTargets,
      },
      errorMessages: {
        ...tempMessage,
      },
    });
  };

  render() {
    const {
      form,
      inputForm,
      isSuccessOpen,
      successMessage,
      isNotDelete,
      isNotEdit,
      isEdit,
      datas,
      tabValue,
      isOpen,
      isMailerOpen,
      selectedDetailId,
      mailerTitle,
      searchCondition,
      errorMessages,
      isPushPaymentLink,
      isPushCreatePaymentLink,
      isSelectOpen,

    } = this.state;
    const isExist = form && form.data && form.data.id;

    return (
      <>
        <RecordHeader
          bottomSection={(
            <>
              {form.data.claimStatusCode === 4 && datas.length > 0 && (
                <>
                  {!isNotEdit && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => this.setState({ mailerTitle: '領収書発行', isPushPaymentLink: false })}
                    >
                      領収書発行
                    </Button>
                  )}
                  <ReceiptDownloadButton id={form.data.id} />
                </>
              )}
              {form.data.claimStatusCode !== 4 && !isNotEdit && datas.length > 0 && (
                <Button variant="contained" size="small" onClick={() => this.setState({ isPushCreatePaymentLink: true })}>支払リンクの作成</Button>
              )}
              {/* {form.data.claimStatusCode !== 4
                && !isNotEdit && datas.length > 0
                && !!form.data.paymentLink && (
                <Button variant="contained" size="small" onClick={()
                => this.setState({ mailerTitle: '支払リンクの送信',
                isPushPaymentLink: true })}>支払リンクの送信</Button>
              )} */}
              {datas.length > 0 && (<ClaimFileDownloadButton id={form.data.id} />)}
              {!isNotEdit && datas.length > 0 && (
                <Button variant="contained" size="small" onClick={this.onSendClaim} startIcon={<SendIcon />}>請求書送付</Button>
              )}
            </>
          )}
        />
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={isExist ? 5 : 12}>
            <RecordHeaderButton
              edit={isEdit}
              disabled={isNotEdit}
              onEdit={this.onClickEditClaim}
            />
            <RecordBox>
              <Accordion title="基本情報">
                {isExist && (
                <DataGrid title="請求番号" value={form.data.claimNumber} />
                )}
                <DataGrid
                  title="案件"
                  isEdit={isEdit}
                  isRequired
                  value={(
                    <Box display="flex" flexWrap="wrap">
                      {inputForm.targets && inputForm.targets.map((p) => (
                        <Box key={p.id} mr={2}>
                          <Link to={detailUrl.OPPORTUNITY_DETAIL + p.id}>
                            {p.opportunityNumber}
                          </Link>
                        </Box>
                      ))}
                    </Box>
                  )}
                >
                  {inputForm.targets.map((s) => (
                    <Chip key={s.id} label={s.opportunityNumber} onDelete={(e) => this.onDeleteTargets(e, s)} sx={{ marginRight: '10px', marginBottom: '5px' }} />
                  ))}
                  <Box mt={2}>
                    <Button size="small" variant="contained" onClick={() => this.setState({ isSelectOpen: true })}>
                      {isSelectOpen}
                      案件選択
                    </Button>
                  </Box>
                  <FormErrorText>{errorMessages.targets}</FormErrorText>
                </DataGrid>
                <DataGrid title="支払期限" value={form.data.paymentDueDate} isEdit={isEdit}>
                  <DatePicker value={inputForm.data.paymentDueDate} fullWidth name="paymentDueDate" onChange={this.onChange} />
                  <FormErrorText>{errorMessages.paymentDueDate}</FormErrorText>
                </DataGrid>
                <DataGrid title="ステータス" isRequired isEdit={isEdit} value={form.data.claimStatusName}>
                  <DataSelectList
                    name="claimStatusCode"
                    target="codes"
                    parameter={{ codeCategory: 'claim_status' }}
                    value={inputForm.data.claimStatusCode}
                    onChange={this.onChange}
                    error={!!errorMessages.claimStatusCode}
                    fullWidth
                    disableClearable
                  />
                  <FormErrorText>{errorMessages.claimStatusCode}</FormErrorText>
                </DataGrid>
                <DataGrid title="入金日" value={form.data.paymentDate} isEdit={isEdit}>
                  <DatePicker value={inputForm.data.paymentDate} fullWidth name="paymentDate" onChange={this.onChange} />
                  <FormErrorText>{errorMessages.paymentDate}</FormErrorText>
                </DataGrid>
                <DataGrid title="振込先口座" isEdit={isEdit} value={form.data.bankAccountInfo}>
                  <DataSelectList
                    name="bankId"
                    target="banks"
                    value={inputForm.data.bankId}
                    onChange={this.onChange}
                    fullWidth
                  />
                  <FormErrorText>{errorMessages.bankAccountInfo}</FormErrorText>
                </DataGrid>
                <>
                  {isExist && (
                    <DataGrid
                      title="支払リンク"
                      value={(
                        <Box style={{
                          display: 'flex', justifyContent: 'start',
                        }}
                        >
                          {
                            !!form.data.paymentLink
                            && <ClipboardCopy value={form.data.paymentLink} />
                          }
                          <Link to={form.data.paymentLink} target="_blank">
                            {form.data.paymentLink}
                          </Link>
                          {!!form.data.paymentLink && <Launch />}
                        </Box>
                      )}
                    />
                  )}
                  <DataGrid title="支払メール" isEdit={isEdit} value={form.data.customerEmail}>
                    <TextField error={!!errorMessages.customerEmail} value={inputForm.data.customerEmail} fullWidth name="customerEmail" onChange={this.onChange} inputProps={{ maxLength: 255 }} />
                    <FormErrorText>{errorMessages.customerEmail}</FormErrorText>
                  </DataGrid>
                  {
                    isExist && (
                      <DataGrid title="Stripe検索用キー" value={form.data.id} />
                    )
                  }
                  <DataGrid title="Stripe連携用備考" isEdit={isEdit} value={form.data.stripeRemarks}>
                    <TextArea value={inputForm.data.stripeRemarks} name="stripeRemarks" onChange={this.onChange} />
                  </DataGrid>
                </>
              </Accordion>
              {isExist && (
                <>
                  <Accordion title="金額">
                    <DataGrid title="ポイント利用額" isEdit={isEdit} value={form.data.pointUsagePrice}>
                      <NumberInput name="pointUsagePrice" onChange={this.onChange} value={inputForm.data.pointUsagePrice} error={!!errorMessages.pointUsagePrice} />
                      <FormErrorText>{errorMessages.pointUsagePrice}</FormErrorText>
                    </DataGrid>
                    {form.data.affiliationId && (
                      <FormHelperText>提携企業の場合は注文にポイントはご利用できません。</FormHelperText>
                    )}
                  </Accordion>
                  <Accordion title="金額明細">
                    <DataGrid title="免税8%小計(税込)" value={form.data.exemptionEightPercentTotalPrice} />
                    <DataGrid title="免税8%消費税" value={form.data.exemptionEightPercentTotalTaxPrice} />
                    <DataGrid title="免税8%割引額" value={form.data.exemptionEightPercentDiscountPrice} />
                    <DataGrid title="免税10%小計(税込)" value={form.data.exemptionTenPercentTotalPrice} />
                    <DataGrid title="免税10%消費税" value={form.data.exemptionTenPercentTotalTaxPrice} />
                    <DataGrid title="免税10%割引額" value={form.data.exemptionTenPercentDiscountPrice} />
                    <DataGrid title="課税8%小計(税込)" value={form.data.taxationEightPercentTotalPrice} />
                    <DataGrid title="課税8%消費税" value={form.data.taxationEightPercentTotalTaxPrice} />
                    <DataGrid title="課税8%割引額" value={form.data.taxationEightPercentDiscountPrice} />
                    <DataGrid title="課税10%小計(税込)" value={form.data.taxationTenPercentTotalPrice} />
                    <DataGrid title="課税10%消費税" value={form.data.taxationTenPercentTotalTaxPrice} />
                    <DataGrid title="課税10%割引額" value={form.data.taxationTenPercentDiscountPrice} />
                  </Accordion>
                  <Accordion title="合計金額">
                    <DataGrid title="手数料(税込)" value={form.data.commissionPrice} />
                    <DataGrid title="合計金額(税込)" value={form.data.totalPrice} />
                    <DataGrid title="割引対象額(税抜)" value={form.data.discountTargetPrice} />
                    <DataGrid title="顧客請求額(税込)" value={form.data.customerBillPrice} />
                  </Accordion>
                </>
              )}
              <Accordion title="備考情報">
                <DataGrid title="請求書備考" isEdit={isEdit} value={form.data.remarks}>
                  <TextArea value={inputForm.data.remarks} name="remarks" onChange={this.onChange} />
                </DataGrid>
                <DataGrid title="領収書備考" isEdit={isEdit} value={form.data.receiptRemarks}>
                  <TextArea value={inputForm.data.receiptRemarks} name="receiptRemarks" onChange={this.onChange} />
                </DataGrid>
              </Accordion>
              {!isEdit && form.data.id && (
              <Box mt={2}><SystemInfo data={form.data} /></Box>
              )}
              {isEdit && (
                <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                  {isExist && (
                    <Button color="inherit" variant="contained" size="small" onClick={this.onClickCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
                  )}
                  <Button variant="contained" size="small" onClick={this.onClickSave}>保存</Button>
                </Box>
              )}
            </RecordBox>
          </Grid>
          {isExist && (
          <Grid item xs={12} md={7}>
            <Tabs
              value={tabValue}
              onChange={(_, value) => this.setState({ tabValue: value })}
              tabs={['請求明細']}
            />
            <TabPanel value={tabValue} index={0}>
              <DetailTable
                rows={datas}
                headCells={this.claimDetailHeadCells}
                onAddOpen={this.onAdd}
                procName="請求明細"
                onDelete={this.onDelete}
                handleRequestSort={this.handleRequestSort}
                order={searchCondition.order}
                orderBy={searchCondition.orderBy}
                isNotEdit={isNotEdit}
                isNotDelete={isNotDelete}
                onEdit={this.onEdit}
              />
            </TabPanel>
          </Grid>
          )}
        </Grid>

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <ClaimDetailDialog
          isOpen={isOpen}
          onClose={this.onClose}
          claimDetailId={selectedDetailId}
          claimId={form.data.id}
        />
        <ClaimFileDialog
          isOpen={isMailerOpen}
          onClose={this.onClose}
          form={form.data}
          sendSuccess={this.onCloseAtSendFileSuccess}
        />
        <SelectOpportunityDialog
          isOpen={isSelectOpen}
          onSelect={this.onSelect}
          onClose={() => this.setState({ isSelectOpen: false })}
        />
        <MailerDialog isOpen={mailerTitle} isPushPaymentLink={isPushPaymentLink} onClose={() => this.setState({ mailerTitle: '' })} title={mailerTitle} form={form.data} />
        <PaymentLinkCreateDialog
          form={form.data}
          isOpen={isPushCreatePaymentLink}
          onClose={() => this.setState({ isPushCreatePaymentLink: false })}
          totalPrice={!datas && datas.length === 0 ? 0 : (datas.reduce(
            (accumulator, currentValue) => accumulator + currentValue.totalPrice,
            0,
          ))}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  claimInfo: state.claimStore,
  claimDetailInfo: state.claimDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ClaimDetail));
