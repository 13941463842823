import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions } from '../../../redux/opportunity/claim/claimState';
import FullScreenMailer from '../../../components/templates/mailer/FullScreenMailer';

const defaultMailerData = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

export default function MailerDialog(props) {
  const {
    isOpen,
    onClose,
    form,
    isPushPaymentLink,
  } = props;

  const dispatch = useDispatch();
  const [mailerData, setMailerData] = useState(defaultMailerData);
  const [loading, setLoading] = React.useState(false);
  const isSendSuccess = useSelector((state) => state.claimStore.isSendSuccess);

  useEffect(() => {
    if (isOpen) {
      setMailerData({
        ...defaultMailerData,
        to: form.to,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (form) {
      setLoading(false);
    }
  }, [form]);

  useEffect(() => {
    if (isSendSuccess) {
      setLoading(false);
      onClose();
      dispatch(claimActions.resetFlgs());
    }
  }, [isSendSuccess]);

  const onSend = (mailForm) => {
    setLoading(true);
    dispatch(claimActions.sendText({
      mailForm,
      id: form.id,
      pushPaymentLink: isPushPaymentLink,
    }));
  };

  return (
    <FullScreenMailer
      isOpen={!!isOpen}
      isPushPaymentLink={isPushPaymentLink}
      title={isPushPaymentLink ? '支払リンク送付' : '領収書リンク送付'}
      onClose={onClose}
      onClickAction={onSend}
      data={mailerData}
      objectData={form || {}}
      loading={loading}
    />
  );
}
